<template>
  <div>
    <ac-module-heading> Statements </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text>
        <div class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex align-center pb-5">
            <ac-company-select @change="onCompanySelect" />
            <ac-button
              v-if="currentTabIndex === 0"
              title="Add Entry"
              :icon="icons.mdiPlus"
              @click="addEntry"
            />
          </div>
          <v-spacer />
          <div class="d-flex align-center pb-5">
            <ac-button
              v-if="currentTabIndex === 1"
              title="Download CSV"
              class="me-3"
              :icon="icons.mdiDownload"
              @click="downloadCSV"
            />
          </div>
        </div>
        <div class="d-flex align-center flex-wrap pb-0">
          <ac-select
            label="User"
            v-model="selectedUser"
            :items="userOptions"
            class="filter-select me-3"
            @change="onFilterChange"
          />
          <ac-select
            label="Year"
            v-model="selectedYear"
            :items="yearOptions"
            class="filter-select me-3"
            @change="onFilterChange"
          />
        </div>
      </v-card-text>
      <ac-tabs :tabs="tabs" v-model="currentTabIndex" @input="onTabChange">
        <template #all-statements>
          <statements-table
            :loading="loading"
            @onTableChange="onTableChange"
            @onStatementRemove="confirmRemove"
          />
        </template>
        <template #broker-statements>
          <v-data-table
            :headers="brokerStatementsHeaders"
            :items="brokerStatements"
            :loading="loading"
            :page.sync="brokerCurrentPage"
            :server-items-length="getBrokerStatementsTotal"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50, 100],
            }"
            @update:items-per-page="onPerPageChange"
            @update:page="onPageChange"
            class="text-no-wrap"
          >
            <template #[`item.January`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.January)"
                :title="item.January"
                :text="true"
                :class="getColorClass(item.January)"
                @click="upsertModal(item.January, item, '1')"
              />
              <span
                v-if="show == displayButton(item.January)"
                :class="getColorClass(item.January)"
                @click="upsertModal(item.January, item, '1')"
              >
                {{ item.January }}
              </span>
            </template>

            <template #[`item.February`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.February)"
                :title="item.February"
                :text="true"
                :class="getColorClass(item.February)"
                @click="upsertModal(item.February, item, '2')"
              />
              <span
                v-if="show == displayButton(item.February)"
                :class="getColorClass(item.February)"
                @click="upsertModal(item.February, item, '2')"
              >
                {{ item.February }}
              </span>
            </template>

            <template #[`item.March`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.March)"
                :title="item.March"
                :text="true"
                :class="getColorClass(item.March)"
                @click="upsertModal(item.March, item, '3')"
              />
              <span
                v-if="show == displayButton(item.March)"
                :class="getColorClass(item.March)"
                @click="upsertModal(item.March, item, '3')"
              >
                {{ item.March }}
              </span>
            </template>

            <template #[`item.April`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.April)"
                :title="item.April"
                :text="true"
                :class="getColorClass(item.April)"
                @click="upsertModal(item.April, item, '4')"
              />
              <span
                v-if="show == displayButton(item.April)"
                :class="getColorClass(item.April)"
                @click="upsertModal(item.April, item, '4')"
              >
                {{ item.April }}
              </span>
            </template>

            <template #[`item.May`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.May)"
                :title="item.May"
                :text="true"
                :class="getColorClass(item.May)"
                @click="upsertModal(item.May, item, '5')"
              />
              <span
                v-if="show == displayButton(item.May)"
                :class="getColorClass(item.May)"
                @click="upsertModal(item.May, item, '5')"
              >
                {{ item.May }}
              </span>
            </template>

            <template #[`item.June`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.June)"
                :title="item.June"
                :text="true"
                :class="getColorClass(item.June)"
                @click="upsertModal(item.June, item, '6')"
              />
              <span
                v-if="show == displayButton(item.June)"
                :class="getColorClass(item.June)"
                @click="upsertModal(item.June, item, '6')"
              >
                {{ item.June }}
              </span>
            </template>

            <template #[`item.July`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.July)"
                :title="item.July"
                :text="true"
                :class="getColorClass(item.July)"
                @click="upsertModal(item.July, item, '7')"
              />
              <span
                v-if="show == displayButton(item.July)"
                :class="getColorClass(item.July)"
                @click="upsertModal(item.July, item, '7')"
              >
                {{ item.July }}
              </span>
            </template>

            <template #[`item.August`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.August)"
                :title="item.August"
                :text="true"
                :class="getColorClass(item.August)"
                @click="upsertModal(item.August, item, '8')"
              />
              <span
                v-if="show == displayButton(item.August)"
                :class="getColorClass(item.August)"
                @click="upsertModal(item.August, item, '8')"
              >
                {{ item.August }}
              </span>
            </template>

            <template #[`item.September`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.September)"
                :title="item.September"
                :text="true"
                :class="getColorClass(item.September)"
                @click="upsertModal(item.September, item, '9')"
              />
              <span
                v-if="show == displayButton(item.September)"
                :class="getColorClass(item.September)"
                @click="upsertModal(item.September, item, '9')"
              >
                {{ item.September }}
              </span>
            </template>

            <template #[`item.October`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.October)"
                :title="item.October"
                :text="true"
                :class="getColorClass(item.October)"
                @click="upsertModal(item.October, item, '10')"
              />
              <span
                v-if="show == displayButton(item.October)"
                :class="getColorClass(item.October)"
                @click="upsertModal(item.October, item, '10')"
              >
                {{ item.October }}
              </span>
            </template>

            <template #[`item.November`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.November)"
                :title="item.November"
                :text="true"
                :class="getColorClass(item.November)"
                @click="upsertModal(item.November, item, '11')"
              />
              <span
                v-if="show == displayButton(item.November)"
                :class="getColorClass(item.November)"
                @click="upsertModal(item.November, item, '11')"
              >
                {{ item.November }}
              </span>
            </template>

            <template #[`item.December`]="{ item }">
              <ac-button
                v-if="show != displayButton(item.December)"
                :title="item.December"
                :text="true"
                :class="getColorClass(item.December)"
                @click="upsertModal(item.December, item, '12')"
              />
              <span
                v-if="show == displayButton(item.December)"
                :class="getColorClass(item.December)"
                @click="upsertModal(item.December, item, '12')"
              >
                {{ item.December }}
              </span>
            </template>
          </v-data-table>
        </template>
      </ac-tabs>
    </v-card>
    <add-statements-modal
      :is-editable="isEditable"
      :edit-item="editItems"
      :company-staff="companyStaff"
      v-model="showStatementsModal"
      @onClose="refetchList"
    ></add-statements-modal>
    <ac-confirmation-dialog
      v-model="showConfirmationDialog"
      description="Are you sure you want to remove this Statement?"
      @onCancel="cancelCompanyRemoval"
      @onOK="onStatementRemove"
    />
  </div>
</template>

<script>
import {
  mdiBellOffOutline,
  mdiBellOutline,
  mdiCheckBold,
  mdiCloseThick,
  mdiDeleteOutline,
  mdiDownload,
  mdiEyeOutline,
  mdiPlus,
  mdiSend,
} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AcButton: () => import('@/components/AcButton'),
    AcInput: () => import('@/components/AcInput'),
    AcTabs: () => import('@/components/AcTabs'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    AcSelect: () => import('@/components/AcSelect'),
    AcCompanySelect: () => import('@/components/AcCompanySelect'),
    StatementsTable: () => import('@/components/Statements/StatementsTable'),
    AddStatementsModal: () => import('@/components/Statements/AddStatementsModal.vue'),
    AcConfirmationDialog: () => import('@/components/AcConfirmationDialog'),
  },

  data() {
    return {
      icons: {
        mdiDownload,
        mdiBellOffOutline,
        mdiBellOutline,
        mdiCheckBold,
        mdiCloseThick,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiPlus,
        mdiSend,
      },
      tabs: [
        { title: 'All Statements', content: 'all-statements' },
        { title: 'Broker Statements', content: 'broker-statements' },
      ],
      brokerStatementsHeaders: [
        { text: 'User', value: 'userName' },
        { text: 'Broker', value: 'broker' },
        { text: 'Account Number', value: 'accountNumber' },
        { text: 'Active Since', value: 'activeSince' },
        { text: 'Jan', value: 'January' },
        { text: 'Feb', value: 'February' },
        { text: 'Mar', value: 'March' },
        { text: 'Apr', value: 'April' },
        { text: 'May', value: 'May' },
        { text: 'Jun', value: 'June' },
        { text: 'Jul', value: 'July' },
        { text: 'Aug', value: 'August' },
        { text: 'Sep', value: 'September' },
        { text: 'Oct', value: 'October' },
        { text: 'Nov', value: 'November' },
        { text: 'Dec', value: 'December' },
      ],
      loading: false,
      editItems: {},
      brokerStatements: [],
      statementsOptions: [],
      brokerStatementsOptions: [],
      currentTabIndex: 0,
      selectedUser: 'All Users',
      selectedYear: 'All',
      itemsPerPage: 10,
      itemsSkip: 0,
      currentPage: 1,
      showStatementsModal: false,
      allStatements: [],
      brokerCurrentPage: 1,
      brokerItemsPerPage: 10,
      brokerItemSkip: 0,
      isEditable: false,
      showConfirmationDialog: false,
      recordToDelete: null,
      show: false,
    }
  },

  computed: {
    ...mapGetters('app', ['selectedCompany']),
    ...mapGetters('paDealing', [
      'companyStaff',
      'userOptions',
      'getStatements',
      'getStatementsTotal',
      'getBrokerStatements',
      'getBrokerStatementsTotal',
    ]),
    displayButton() {
      return value => {
        if (value === 'Not Required') {
          return false
        } else if (value === 'Missing') {
          return true
        } else if (value === 'Closed') {
          return false
        } else if (value === 'Obtained') {
          return true
        } else {
          return false
        }
      }
    },

    getColorClass() {
      return value => {
        if (value === 'Not Required') {
          return 'warning--text text-capitalize'
        } else if (value === 'Missing') {
          return 'error--text text-capitalize'
        } else if (value === 'Closed') {
          return 'primary--text text-capitalize'
        } else {
          return 'success--text text-capitalize'
        }
      }
    },

    yearOptions() {
      const currentYear = new Date().getFullYear()
      const yearArray = this.currentTabIndex === 0 ? ['All'] : []
      for (let i = currentYear; i >= 2017; i--) {
        yearArray.push(i)
      }
      return yearArray
    },
  },

  methods: {
    ...mapActions('paDealing', [
      'fetchCompanyStaff',
      'fetchStatements',
      'fetchBrokerStatements',
      'removeStatement',
      'fetchAccounts',
    ]),

    async onCompanySelect(id) {
      // this.selectedUser = null
      this.setDefaultYear()
      this.setDefaultUser()
      if (this.currentTabIndex == 0) {
        await this.loadStatements()
      } else {
        await this.loadBrokerStatements()
      }
      await this.loadCompanyStaff(id)
    },
    async onTabChange() {
      this.loading = true
      this.itemsSkip = 0
      this.currentPage = 1
      this.itemsPerPage = 10
      this.brokerCurrentPage = 1
      this.brokerItemsPerPage = 10
      this.brokerItemSkip = 0

      this.setDefaultYear()
      if (this.currentTabIndex == 0) {
        await this.loadStatements()
      } else {
        await this.loadBrokerStatements()
      }
      await this.loadCompanyStaff(this.selectedCompany)
    },
    async onPerPageChange(val) {
      this.itemsPerPage = val
      this.brokerItemsPerPage = val

      await this.loadStatements()
      await this.loadBrokerStatements()
    },
    async onPageChange(val) {
      this.brokerItemSkip = (val - 1) * this.brokerItemsPerPage

      await this.loadBrokerStatements()
    },
    async onFilterChange() {
      this.itemsSkip = 0
      this.brokerItemSkip = 0

      await this.loadStatements()
      await this.loadBrokerStatements()
    },
    async onStatementRemove() {
      this.showConfirmationDialog = false
      const { success } = await this.removeStatement(this.recordToDelete)
      if (success) await this.loadStatements()
    },

    setDefaultYear() {
      this.selectedYear = this.currentTabIndex === 0 ? 'All' : new Date().getFullYear()
    },
    setDefaultUser() {
      this.selectedUser = 'All Users'
    },
    cancelCompanyRemoval() {
      this.showConfirmationDialog = false
      this.recordToDelete = null
    },
    confirmRemove(id) {
      this.showConfirmationDialog = true
      this.recordToDelete = id
    },
    async loadCompanyStaff(id) {
      this.brokerItemSkip = 0
      this.brokerCurrentPage = 1

      await this.fetchCompanyStaff(id)
      await this.fetchAccounts(this.selectedCompany)
    },
    async onTableChange(payload) {
      const { itemsPerPage, itemsSkip } = payload
      this.itemsPerPage = itemsPerPage
      this.itemsSkip = itemsSkip

      this.loadStatements()
    },
    async refetchList() {
      await this.loadStatements()
      await this.loadBrokerStatements()
    },

    downloadCSV() {
      this.AcNotify({
        type: 'info',
        message:
          'Please check your downloads folder for a file named <strong>brokerStatements.csv</strong>',
        position: 'bottom left',
      })

      const sm = this.brokerStatements.map(record => [
        record.userName || '',
        record.broker || '',
        record.accountNumber || '',
        record.activeSince || '',
        record.January,
        record.February,
        record.March,
        record.April,
        record.May,
        record.June,
        record.July,
        record.August,
        record.September,
        record.October,
        record.November,
        record.December,
      ])

      sm.unshift([
        'User',
        'Broker',
        'Account Number',
        'Active Since',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ])

      this.exportToCsv('brokerStatements.csv', sm)
    },

    async upsertModal(val, item, mon) {
      if (val == 'Missing') {
        this.isEditable = false
        this.showStatementsModal = true
      }

      if (val == 'Obtained') {
        this.editItems = item
        this.editItems.monthRecord = item.monthData[mon]
        this.isEditable = true
        this.showStatementsModal = true
      }
    },

    downloadCSV() {
      this.AcNotify({
        type: 'info',
        message:
          'Please check your downloads folder for a file named <strong>brokerStatements.csv</strong>',
        position: 'bottom left',
      })

      const sm = this.brokerStatements.map(record => [
        record.userName || '',
        record.broker || '',
        record.accountNumber || '',
        record.activeSince || '',
        record.January,
        record.February,
        record.March,
        record.April,
        record.May,
        record.June,
        record.July,
        record.August,
        record.September,
        record.October,
        record.November,
        record.December,
      ])

      sm.unshift([
        'User',
        'Broker',
        'Account Number',
        'Active Since',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ])

      this.exportToCsv('brokerStatements.csv', sm)
    },

    async upsertModal(val, item, mon) {
      if (val == 'Missing') {
        this.isEditable = false
        this.showStatementsModal = true
      }

      if (val == 'Obtained') {
        this.editItems = item
        this.editItems.monthRecord = item.monthData[mon]
        this.isEditable = true
        this.showStatementsModal = true
      }
    },

    async loadStatements() {
      const { selectedCompany, selectedUser, selectedYear, itemsPerPage, itemsSkip } = this

      const payload = {
        user: selectedUser,
        year: selectedYear,
        statementOnly: true, // * For statements table records
        showInactiveUsers: true,
        // * for pagination
        skipData: itemsSkip,
        limit: itemsPerPage,
      }
      this.loading = true
      await this.fetchStatements({ companyID: selectedCompany, payload })
      this.loading = false
    },

    async loadBrokerStatements() {
      const payload = {
        user: this.selectedUser,
        companyId: this.selectedCompany,
        year: this.selectedYear,
        showInactiveUsers: false,
        statementOnly: false,
        skipData: this.brokerItemSkip,
        limit: this.brokerItemsPerPage,
      }
      this.loading = true
      await this.fetchBrokerStatements(payload)
      this.loading = false
      this.brokerStatements = []
      await this.getMonthsWiseData()
    },

    addEntry() {
      this.isEditable = false
      this.showStatementsModal = true
    },

    getMonthsWiseData() {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      this.getBrokerStatements.forEach(obj => {
        const result = {}
        let year = this.selectedYear

        if (year == 'All') {
          year = new Date().getFullYear()
        }

        for (let i = 1; i <= 12; i++) {
          const key = i.toString()
          const month = months[i - 1]
          const text = obj[year][key] && obj[year][key].text ? obj[year][key].text : '-'

          result[month] = text
          result.monthData = obj[year]
          result.userName = obj.userName
          result.userId = obj.userId
          result.broker = obj.broker
          result.brokerId = obj.brokerId
          result.accountNumber = obj.accountNumber
          result.activeSince = obj.activeSince
        }
        this.brokerStatements.push(result)
      })
    },
  },

  async created() {
    await this.loadStatements()
    await this.loadCompanyStaff(this.selectedCompany)
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .filter-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
