import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" Statements ")]),_c(VCard,{staticClass:"card-wrapper"},[_c(VCardText,[_c('div',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-company-select',{on:{"change":_vm.onCompanySelect}}),(_vm.currentTabIndex === 0)?_c('ac-button',{attrs:{"title":"Add Entry","icon":_vm.icons.mdiPlus},on:{"click":_vm.addEntry}}):_vm._e()],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center pb-5"},[(_vm.currentTabIndex === 1)?_c('ac-button',{staticClass:"me-3",attrs:{"title":"Download CSV","icon":_vm.icons.mdiDownload},on:{"click":_vm.downloadCSV}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('ac-select',{staticClass:"filter-select me-3",attrs:{"label":"User","items":_vm.userOptions},on:{"change":_vm.onFilterChange},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}}),_c('ac-select',{staticClass:"filter-select me-3",attrs:{"label":"Year","items":_vm.yearOptions},on:{"change":_vm.onFilterChange},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1)]),_c('ac-tabs',{attrs:{"tabs":_vm.tabs},on:{"input":_vm.onTabChange},scopedSlots:_vm._u([{key:"all-statements",fn:function(){return [_c('statements-table',{attrs:{"loading":_vm.loading},on:{"onTableChange":_vm.onTableChange,"onStatementRemove":_vm.confirmRemove}})]},proxy:true},{key:"broker-statements",fn:function(){return [_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.brokerStatementsHeaders,"items":_vm.brokerStatements,"loading":_vm.loading,"page":_vm.brokerCurrentPage,"server-items-length":_vm.getBrokerStatementsTotal,"footer-props":{
            'items-per-page-options': [10, 20, 30, 40, 50, 100],
          }},on:{"update:page":[function($event){_vm.brokerCurrentPage=$event},_vm.onPageChange],"update:items-per-page":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"item.January",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.January))?_c('ac-button',{class:_vm.getColorClass(item.January),attrs:{"title":item.January,"text":true},on:{"click":function($event){return _vm.upsertModal(item.January, item, '1')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.January))?_c('span',{class:_vm.getColorClass(item.January),on:{"click":function($event){return _vm.upsertModal(item.January, item, '1')}}},[_vm._v(" "+_vm._s(item.January)+" ")]):_vm._e()]}},{key:"item.February",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.February))?_c('ac-button',{class:_vm.getColorClass(item.February),attrs:{"title":item.February,"text":true},on:{"click":function($event){return _vm.upsertModal(item.February, item, '2')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.February))?_c('span',{class:_vm.getColorClass(item.February),on:{"click":function($event){return _vm.upsertModal(item.February, item, '2')}}},[_vm._v(" "+_vm._s(item.February)+" ")]):_vm._e()]}},{key:"item.March",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.March))?_c('ac-button',{class:_vm.getColorClass(item.March),attrs:{"title":item.March,"text":true},on:{"click":function($event){return _vm.upsertModal(item.March, item, '3')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.March))?_c('span',{class:_vm.getColorClass(item.March),on:{"click":function($event){return _vm.upsertModal(item.March, item, '3')}}},[_vm._v(" "+_vm._s(item.March)+" ")]):_vm._e()]}},{key:"item.April",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.April))?_c('ac-button',{class:_vm.getColorClass(item.April),attrs:{"title":item.April,"text":true},on:{"click":function($event){return _vm.upsertModal(item.April, item, '4')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.April))?_c('span',{class:_vm.getColorClass(item.April),on:{"click":function($event){return _vm.upsertModal(item.April, item, '4')}}},[_vm._v(" "+_vm._s(item.April)+" ")]):_vm._e()]}},{key:"item.May",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.May))?_c('ac-button',{class:_vm.getColorClass(item.May),attrs:{"title":item.May,"text":true},on:{"click":function($event){return _vm.upsertModal(item.May, item, '5')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.May))?_c('span',{class:_vm.getColorClass(item.May),on:{"click":function($event){return _vm.upsertModal(item.May, item, '5')}}},[_vm._v(" "+_vm._s(item.May)+" ")]):_vm._e()]}},{key:"item.June",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.June))?_c('ac-button',{class:_vm.getColorClass(item.June),attrs:{"title":item.June,"text":true},on:{"click":function($event){return _vm.upsertModal(item.June, item, '6')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.June))?_c('span',{class:_vm.getColorClass(item.June),on:{"click":function($event){return _vm.upsertModal(item.June, item, '6')}}},[_vm._v(" "+_vm._s(item.June)+" ")]):_vm._e()]}},{key:"item.July",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.July))?_c('ac-button',{class:_vm.getColorClass(item.July),attrs:{"title":item.July,"text":true},on:{"click":function($event){return _vm.upsertModal(item.July, item, '7')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.July))?_c('span',{class:_vm.getColorClass(item.July),on:{"click":function($event){return _vm.upsertModal(item.July, item, '7')}}},[_vm._v(" "+_vm._s(item.July)+" ")]):_vm._e()]}},{key:"item.August",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.August))?_c('ac-button',{class:_vm.getColorClass(item.August),attrs:{"title":item.August,"text":true},on:{"click":function($event){return _vm.upsertModal(item.August, item, '8')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.August))?_c('span',{class:_vm.getColorClass(item.August),on:{"click":function($event){return _vm.upsertModal(item.August, item, '8')}}},[_vm._v(" "+_vm._s(item.August)+" ")]):_vm._e()]}},{key:"item.September",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.September))?_c('ac-button',{class:_vm.getColorClass(item.September),attrs:{"title":item.September,"text":true},on:{"click":function($event){return _vm.upsertModal(item.September, item, '9')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.September))?_c('span',{class:_vm.getColorClass(item.September),on:{"click":function($event){return _vm.upsertModal(item.September, item, '9')}}},[_vm._v(" "+_vm._s(item.September)+" ")]):_vm._e()]}},{key:"item.October",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.October))?_c('ac-button',{class:_vm.getColorClass(item.October),attrs:{"title":item.October,"text":true},on:{"click":function($event){return _vm.upsertModal(item.October, item, '10')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.October))?_c('span',{class:_vm.getColorClass(item.October),on:{"click":function($event){return _vm.upsertModal(item.October, item, '10')}}},[_vm._v(" "+_vm._s(item.October)+" ")]):_vm._e()]}},{key:"item.November",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.November))?_c('ac-button',{class:_vm.getColorClass(item.November),attrs:{"title":item.November,"text":true},on:{"click":function($event){return _vm.upsertModal(item.November, item, '11')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.November))?_c('span',{class:_vm.getColorClass(item.November),on:{"click":function($event){return _vm.upsertModal(item.November, item, '11')}}},[_vm._v(" "+_vm._s(item.November)+" ")]):_vm._e()]}},{key:"item.December",fn:function(ref){
          var item = ref.item;
return [(_vm.show != _vm.displayButton(item.December))?_c('ac-button',{class:_vm.getColorClass(item.December),attrs:{"title":item.December,"text":true},on:{"click":function($event){return _vm.upsertModal(item.December, item, '12')}}}):_vm._e(),(_vm.show == _vm.displayButton(item.December))?_c('span',{class:_vm.getColorClass(item.December),on:{"click":function($event){return _vm.upsertModal(item.December, item, '12')}}},[_vm._v(" "+_vm._s(item.December)+" ")]):_vm._e()]}}],null,true)})]},proxy:true}]),model:{value:(_vm.currentTabIndex),callback:function ($$v) {_vm.currentTabIndex=$$v},expression:"currentTabIndex"}})],1),_c('add-statements-modal',{attrs:{"is-editable":_vm.isEditable,"edit-item":_vm.editItems,"company-staff":_vm.companyStaff},on:{"onClose":_vm.refetchList},model:{value:(_vm.showStatementsModal),callback:function ($$v) {_vm.showStatementsModal=$$v},expression:"showStatementsModal"}}),_c('ac-confirmation-dialog',{attrs:{"description":"Are you sure you want to remove this Statement?"},on:{"onCancel":_vm.cancelCompanyRemoval,"onOK":_vm.onStatementRemove},model:{value:(_vm.showConfirmationDialog),callback:function ($$v) {_vm.showConfirmationDialog=$$v},expression:"showConfirmationDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }